@import "~@overview-services/ovcomponents-react/dist/styles/webfont"
@import "~@overview-services/ovcomponents-react/dist/styles/icons"
@import "~@overview-services/ovcomponents-react/dist/styles/classes.icons"
@import "~@overview-services/ovcomponents-react/dist/styles/colors"
@import "~@overview-services/ovcomponents-react/dist/styles/values"
@import "~@overview-services/ovcomponents-react/dist/styles/themes"
@import "~@overview-services/ovcomponents-react/dist/styles/mixins"
@import "~@overview-services/ovcomponents-react/dist/styles/reset"

@keyframes fadeInScaleUp
  0%
    opacity: 0
    transform: scale(0.9)
  100%
    opacity: 1
    transform: scale(1)

#error404-content
    #title-grid
        margin-top: $spacing-xxl
        grid-template-columns: 1fr auto 1fr
        grid-gap: $spacing-m
        .error404-title h1
            color: #121212
            font-size: calc(4 * $text-xxl)
            opacity: 0
            animation: fadeInScaleUp 0.8s 1.6s forwards
        .error404-logo
            object
                width: calc(4 * $text-xxl)
                height: calc(4 * $text-xxl)
                transform: translateY(1 / 6 * $text-xxl)
    #error404-notice p
        max-width: calc(14 * $text-xxl)
        font-size: $text-m
        text-align: center
        opacity: 0
        animation: fadeInScaleUp 1.2s 2.8s forwards

h5
    color: $amethyst
    margin-block-end: calc(2 * $spacing-m)

.signature
    justify-self: start
    margin: 0 0
    transform: translateY(-1 * $spacing-xs)
